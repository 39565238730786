(function () {
  'use strict';

  /* @ngdoc object
   * @name neo.home.cancellations.edit
   * @description
   *
   */
  angular
    .module('neo.home.cancellations.edit', [
      'ui.router'
    ]);
}());
